import { Language } from '@lokalise/node-api/dist/models/language';
import { HeaderConfig } from '@store/core/core.interface';

export const withCloseButton = {
  enableBackButton: false,
  hideLogo: true,
  closeButton: true,
  stepData: {
    showStepInfo: false,
  },
  showAmountChip: false,
} as Partial<HeaderConfig>;

export const withChipAndBack = {
  enableBackButton: true,
  hideLogo: true,
  showAmountChip: true,
} as Partial<HeaderConfig>;

export const withChipAndLogo = {
  enableBackButton: false,
  hideLogo: false,
  stepData: {
    showStepInfo: false,
  },
  showAmountChip: true,
  onPopState: false,
} as Partial<HeaderConfig>;
export const withMenuAndLogo = {
  enableBackButton: false,
  hideLogo: false,
  showAmountChip: false,
  showMenu: false,
};

export const withMenuAndBack = {
  enableBackButton: true,
  hideLogo: true,
  showAmountChip: false,
  showMenu: false,
};

export const withMenuBackAndLogo = {
  enableBackButton: true,
  hideLogo: false,
  showAmountChip: false,
  showMenu: false,
  showCenterLogo: true,
};

export const withBackOnly = {
  enableBackButton: true,
  hideLogo: true,
  showAmountChip: false,
  showMenu: false,
  showCenterLogo: false,
};
export const noFooterandLangBtn = {
  enableBackButton: false,
  hideLogo: true,
  hideFooter: true,
  LanguageButton: true
}